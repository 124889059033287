import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import JiggerIcon from "../../../components/CustomIcons/JiggerIcon";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import NamePencilIcon from "../../../components/CustomIcons/NamePencilIcon";

const CreateRecipeGuide = () => {
  const guideData = [
    {
      number: "1",
      icon: (
        <NamePencilIcon
          sx={{
            fontSize: { xs: 80, md: 120 },
            color: (theme) => theme.palette.terrain[400],
          }}
        />
      ),
      header: "Give it a Name",
      description:
        "Whether it is a classic cocktail, a unique dish, or a creative riff, start by naming your recipe.",
    },
    {
      number: "2",
      icon: (
        <JiggerIcon
          sx={{
            fontSize: { xs: 80, md: 120 },
            color: (theme) => theme.palette.terrain[400],
          }}
        />
      ),
      header: "Add Ingredients",
      description:
        "Select ingredients from your product library and include any sub-recipes you have already created.",
    },
    {
      number: "3",
      icon: (
        <SavingsOutlinedIcon
          sx={{
            fontSize: { xs: 80, md: 120 },
            color: (theme) => theme.palette.terrain[400],
          }}
        />
      ),
      header: "Analyze the Cost",
      description:
        "Get a detailed breakdown of ingredient costs, labor expenses, and total recipe cost to optimize pricing.",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderRadius: 4,
          width: { xs: "300px", md: "920px" },
          padding: 2,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 8,
            }}
          >
            <Typography
              variant="h3"
              sx={{ textAlign: "center", fontSize: { xs: 24, md: 24 } }}
            >
              Create Your Own Recipes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                marginTop: 2,
                fontSize: { xs: 12, md: 16 },
                paddingLeft: 4,
                paddingRight: 4,
              }}
            >
              Follow the steps below to get started!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", marginTop: 6 }}
          >
            <Button
              variant="contained"
              sx={{ width: "212px", height: "40px", marginLeft: 1 }}
              // onClick={createQuickstart}
            >
              <Typography
                variant="largeButton"
                sx={{
                  fontSize: { xs: 10, md: 16 },
                  lineHeight: { xs: 1.2, md: 1.4 },
                }}
              >
                + Create New Recipe
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ width: "100%", padding: 12 }}>
            <Grid container direction="row" spacing={6}>
              {guideData.map((x) => (
                <Grid
                  key={x.id}
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "180px", md: "242px" },
                      borderRadius: 3,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.terrain[300],
                      padding: 2,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          width: "48px",
                          height: "48px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: (theme) =>
                            theme.palette.terrain[100],
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{ color: (theme) => theme.palette.terrain[500] }}
                        >
                          {x.number}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {x.icon}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "90%",
                      marginTop: 4,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: 18, md: 24 },
                        lineHeight: { xs: 1.2, md: 1.4 },
                      }}
                    >
                      {x.header}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 4,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: 12, md: 16 },
                        lineHeight: { xs: 1.2, md: 1.4 },
                      }}
                    >
                      {x.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateRecipeGuide;
