import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  Collapse,
  InputAdornment,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { v4 as guidGenerator } from "uuid";
import {
  productSizeTypes,
  unitOfMeasurementTypes,
  unitSizeToUomSizeTypeMap,
} from "../../../clients/inventoryObjects";
import AutoCompleteStyled from "../../AutoCompleteStyled";
import utilFunctions from "../../../utilities/utilFunctions";
import UnitOfMeasurementSelector from "./components/UnitOfMeasurementSelector";

const sizeTypeSelectOptions = [
  { id: productSizeTypes.Volumetric, name: "Volumetric" },
  { id: productSizeTypes.Mass, name: "Mass" },
  { id: productSizeTypes.Arbitrary, name: "Count" },
];

const optionsForPurchaseUnitName = [
  {
    id: 1,
    name: "Bottle",
    suggestionText:
      "For a single bottle purchase unit, the unit amount should be 1",
  },
  {
    id: 2,
    name: "Case",
    suggestionText:
      "For a case purchase unit, the unit amount should be the number of bottles/bags/units in the case",
  },
  {
    id: 3,
    name: "Bag",
    suggestionText:
      "For a single bag purchase unit, the unit amount is often 1",
  },
  {
    id: 4,
    name: "Keg",
    suggestionText: "For a keg purchase unit, the unit amount is often 1",
  },
  {
    id: -1,
    name: "Custom",
    suggestionText:
      "Ensure the value represents the number of units you purchase for this format (e.x. a case may contain 6 units of a format that is a 750 mL bottle)",
  },
];

const optionsForPackagingType = [
  { id: 1, name: "Bottle" },
  { id: 2, name: "Bag" },
  { id: 3, name: "Can" },
  { id: 4, name: "Keg" },
  { id: 5, name: "Box" },
  { id: 6, name: "Jug" },
  { id: 7, name: "Jar" },
  { id: 8, name: "Unit" },
  { id: -1, name: "Custom" },
];
const defaultsForSizes = {
  1: {
    nameOfDefault: "milliliter",
    packagingTypeDefault: 1,
  },
  2: {
    nameOfDefault: "pound",
    packagingTypeDefault: 2,
  },
  3: {
    nameOfDefault: null,
    packagingTypeDefault: 8,
  },
};

const CreateProductModal = ({
  isOpen,
  closeModal,
  loading,
  createProductRecipeRef,
  unitsOfMeasurement,
  inventorySettings,
  vendorData,
  expenseGroupData,
  familyData,
  createProduct,
  createProductPrefill,
}) => {
  const [purchaseExpanded, setPurchaseExpanded] = useState(false);
  const { currencySymbol } = useVendorCurrency(inventorySettings?.currency);
  const [productCreationData, setProductCreationData] = useState({});
  const [productFormatCreationData, setProductFormatCreationData] = useState(
    {}
  );
  const [purchaseUnitCreationData, setPurchaseUnitCreationData] = useState({});
  const [packagingTypeSelection, setPackagingTypeSelection] = useState(1);
  const [customPackagingTypeValue, setCustomPackagingTypeValue] = useState("");
  const [packagingTypeDirty, setPackagingTypeDirty] = useState(false);
  const [purchaseUnitNameSelection, setPurchaseUnitNameSelection] = useState(1);
  const [customPurchaseUnitNameValue, setCustomPurchaseUnitNameValue] =
    useState("");
  const [fieldsWithError, setFieldsWithError] = useState({});
  const [errorDuringCreation, toggleErrorDuringCreation] = useState(false);
  const { familyMap, categoryMap, subcategoryMap, productFamilies } =
    familyData;

  useEffect(() => {
    setProductCreationData({
      name: createProductPrefill || "",
      useManualFormatCreation: true,
      id: guidGenerator(),
      productFamilyId: null,
      categoryId: null,
      subcategoryId: null,
    });

    setProductFormatCreationData({
      id: guidGenerator(),
      size: {
        isDefaultSize: true,
        size: null,
        unitOfMeasurement: unitsOfMeasurement.find(
          (x) =>
            x.type === unitOfMeasurementTypes.Volumetric &&
            x.name.toLowerCase() === "milliliter"
        ),
        type: productSizeTypes.Volumetric,
      },
      isDefault: true,
    });

    setPurchaseUnitCreationData({
      isDefault: true,
      unitAmount: null,
      price: {
        currencyType: inventorySettings.currency,
        currencyUnit: inventorySettings.currencyUnit,
        amount: null,
      },
    });
    setPackagingTypeSelection(1);
    setCustomPackagingTypeValue("");
    setPackagingTypeDirty(false);
    setPurchaseUnitNameSelection(1);
    setCustomPurchaseUnitNameValue("");
    setFieldsWithError({});
    toggleErrorDuringCreation(false);
  }, [isOpen, createProductRecipeRef]);

  const handleNameUpdate = (e) => {
    if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
      const errors = { ...fieldsWithError };
      delete errors.name;
      setFieldsWithError(errors);
    }

    setProductCreationData({
      ...productCreationData,
      name: e.target.value,
    });
  };

  const handleExpandPurchaseClick = () => {
    setPurchaseExpanded(!purchaseExpanded);
  };

  const handleUpdateFamily = (newFamily) => {
    if (newFamily.id == productCreationData.productFamilyId) {
      return;
    }

    if (newFamily.id !== null && newFamily.id !== undefined && newFamily.id !== "") {
      const errors = { ...fieldsWithError };
      delete errors.productFamily;
      setFieldsWithError(errors);
    }

    setProductCreationData({
      ...productCreationData,
      productFamilyId: newFamily.id,
      categoryId: null,
      subcategoryId: null,
    });
  };

  const handleUpdateCategory = (newCategory) => {
    if (newCategory.id == productCreationData.categoryId) {
      return;
    }

    setProductCreationData({
      ...productCreationData,
      categoryId: newCategory.id,
      subcategoryId: null,
    });
  };

  const handleUpdateSubcategory = (newSubcategory) => {
    if (newSubcategory.id == productCreationData.subcategoryId) {
      return;
    }

    setProductCreationData({
      ...productCreationData,
      subcategoryId: newSubcategory.id,
    });
  };

  const handleClearFamily = () => {
    setProductCreationData({
      ...productCreationData,
      productFamilyId: null,
      categoryId: null,
      subcategoryId: null,
    });
  };

  const handleClearCategory = () => {
    setProductCreationData({
      ...productCreationData,
      categoryId: null,
      subcategoryId: null,
    });
  };

  const handleClearSubcategory = () => {
    setProductCreationData({ ...productCreationData, subcategoryId: null });
  };

  const handleUpdateSizeType = (e) => {
    if (e.target.value === productFormatCreationData.size.type) {
      return;
    }

    if (!packagingTypeDirty) {
      setPackagingTypeSelection(
        defaultsForSizes[e.target.value]?.packagingTypeDefault
      );
    }

    const defaultUom = defaultsForSizes[e.target.value]?.nameOfDefault;

    setProductFormatCreationData({
      ...productFormatCreationData,
      size: {
        ...productFormatCreationData.size,
        type: e.target.value,
        unitOfMeasurement: unitsOfMeasurement.find(
          (x) =>
            x.type === unitSizeToUomSizeTypeMap[e.target.value] &&
            x.name.toLowerCase() === defaultUom
        ),
      },
    });
  };

  const handleUpdatePackagingType = (e) => {
    setPackagingTypeSelection(e.target.value);
    setPackagingTypeDirty(true);
  };

  const handleUpdateCustomPackagingType = (e) => {
    if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
      const errors = { ...fieldsWithError };
      delete errors.customPackagingType;
      setFieldsWithError(errors);
    }

    setCustomPackagingTypeValue(e.target.value);
  };

  const handleUpdateQuantity = (e) => {
    const { value } = e.target;

    if (value !== null && value !== undefined && value !== "") {
      const errors = { ...fieldsWithError };
      delete errors.size;
      setFieldsWithError(errors);
    }
    
    setProductFormatCreationData({
      ...productFormatCreationData,
      size: {
        ...productFormatCreationData.size,
        size: value,
      },
    });
  };

  const handleUpdateCustomPurchaseUnitName = (e) => {
    setCustomPurchaseUnitNameValue(e.target.value);
  };

  const handleUpdatePurchaseUnitNameSelection = (e) => {
    setPurchaseUnitNameSelection(e.target.value);
  };

  const handleUpdatePurchaseUnitPrice = (e) => {
    const { value } = e.target;

    if (value !== null && value !== undefined && value !== "") {
      const errors = { ...fieldsWithError };
      delete errors.price;
      setFieldsWithError(errors);
    }

    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      price: {
        ...purchaseUnitCreationData.price,
        amount: value,
      },
    });
  };

  const handleUpdatePurchaseUnitUnitAmount = (e) => {
    const { value } = e.target;

    if (value !== null && value !== undefined && value !== "") {
      const errors = { ...fieldsWithError };
      delete errors.unitAmount;
      setFieldsWithError(errors);
    }

    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      unitAmount: value,
    });
  };

  const handleSelectVendor = (newVendor) => {
    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      vendor: newVendor,
    });
  };

  const handleClearVendor = () => {
    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      vendor: null,
    });
  };

  const handleUpdateExpenseGroup = (newExpenseGroup) => {
    if (newExpenseGroup.id == purchaseUnitCreationData.expenseGroup?.id) {
      return;
    }

    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      expenseGroup: newExpenseGroup,
      expenseSubgroup: null,
    });
  };

  const handleSKUUpdate = (e) => {
    const { value } = e.target;
    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      SKU: value,
    });
  };

  const handleClearExpenseGroup = () => {
    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      expenseGroup: null,
      expenseSubgroup: null,
    });
  };

  const handleUpdateExpenseSubgroup = (newSubgroup) => {
    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      expenseSubgroup: newSubgroup,
    });
  };

  const handleClearExpenseSubgroup = () => {
    setPurchaseUnitCreationData({
      ...purchaseUnitCreationData,
      expenseSubgroup: null,
    });
  };

  const handleUoMUpdate = (e) => {
    const uom = unitsOfMeasurement.find((x) => x.id === e.target.value);

    setProductFormatCreationData({
      ...productFormatCreationData,
      size: {
        ...productFormatCreationData.size,
        unitOfMeasurement: uom,
        size: uom?.isKegSize ? null : productFormatCreationData.size.size,
      },
    });
  };

  const validateFields = () => {
    toggleErrorDuringCreation(false);
    const errors = {};
    if (!productCreationData.name) {
      errors.name = "Product name is required";
    }
    if (!productCreationData.productFamilyId) {
      errors.productFamily = "Product family is required";
    }
    if (!productFormatCreationData.size.size && productFormatCreationData?.size?.unitOfMeasurement?.isKegSize === false) {
      errors.size = "Amount is required";
    }
    if (
      !productFormatCreationData.size.unitOfMeasurement &&
      productFormatCreationData?.size?.type !== productSizeTypes.Arbitrary
    ) {
      errors.unitOfMeasurement = "Unit of measurement is required";
    }
    if (
      purchaseUnitCreationData.unitAmount === null ||
      purchaseUnitCreationData.unitAmount === undefined ||
      purchaseUnitCreationData.unitAmount === ""
    ) {
      errors.unitAmount = "Unit amount is required";
    }
    if (
      purchaseUnitCreationData.price.amount === null ||
      purchaseUnitCreationData.price.amount === undefined ||
      purchaseUnitCreationData.price.amount === ""
    ) {
      errors.price = "Price is required";
    }
    if (packagingTypeSelection === -1 && !customPackagingTypeValue) {
      errors.customPackagingType =
        "You must supply a value for packaging type when using a custom value";
    }
    if (purchaseUnitNameSelection === -1 && !customPurchaseUnitNameValue) {
      errors.customPurchaseUnitName =
        "You must supply a value for purchase unit name when using a custom value";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    toggleErrorDuringCreation(false);
    e.preventDefault();
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setFieldsWithError(errors);
      return;
    } else {
      setFieldsWithError({});
    }

    const missingTypes = [
      productSizeTypes.Volumetric,
      productSizeTypes.Mass,
      productSizeTypes.Arbitrary,
    ]
      .filter((x) => x !== productFormatCreationData.size.type)
      .map((x) => ({ type: x, isDefaultSize: false }));
    const sizesForPayload = [...missingTypes, productFormatCreationData.size];

    const product = {
      ...productCreationData,
      productFormats: [
        {
          ...productFormatCreationData,
          sizes: sizesForPayload,
          packagingType:
            packagingTypeSelection === -1
              ? customPackagingTypeValue
              : optionsForPackagingType.find(
                  (x) => x.id === packagingTypeSelection
                ).name,
          purchaseDetails: [
            {
              ...purchaseUnitCreationData,
              price: {
                amount: utilFunctions.convertCurrencyToCents(
                  purchaseUnitCreationData.price.amount
                ),
                currencyType: inventorySettings.currency,
                currencyUnit: inventorySettings.currencyUnit,
              },

              name:
                purchaseUnitNameSelection === -1
                  ? customPurchaseUnitNameValue
                  : optionsForPurchaseUnitName.find(
                      (x) => x.id === purchaseUnitNameSelection
                    ).name,
              vendorId: purchaseUnitCreationData.vendor?.id,
              expenseGroupId: purchaseUnitCreationData.expenseGroup?.id,
              expenseSubgroupId: purchaseUnitCreationData.expenseSubgroup?.id,
              amount: purchaseUnitCreationData.unitAmount,
              isDefault: purchaseUnitCreationData.isDefault,
              SKU: purchaseUnitCreationData.SKU,
            },
          ],
        },
      ],
    };

    const isSuccess = await createProduct(product);
    if (!isSuccess) {
      toggleErrorDuringCreation(true);
      return;
    }

    closeModal();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box sx={[modalBoxStyle, { height: "80%", overflow: "scroll" }]}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography variant="h2">Create new Product</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            {/* PRODUCT NAME SECTION */}
            <Box sx={{ marginTop: 4, width: "100%" }}>
              <Typography variant="body1">
                In order to perform cost analysis on a product, we need to know
                some details about it. The default Product Format & Purchase
                Units can be set up now. You can create more in the Product
                Library.
              </Typography>
              {errorDuringCreation && (
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    marginTop: 4,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ color: (theme) => theme.palette.error[500] }}
                  >
                    Oh no! There was a problem creating your product. You can
                    try again or try refreshing the page to see if it resolves.
                    If the problem persists, please contact support.
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  height: "2px",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[200],
                  marginTop: 6,
                }}
              />
              <Typography variant="h3" sx={{ marginTop: 6 }}>
                Product
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 6 }}>
                Product Name*
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                Use the official brand name whenever possible
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  size="small"
                  error={!!fieldsWithError.name}
                  value={productCreationData.name}
                  onChange={handleNameUpdate}
                  required
                  fullWidth
                  autoComplete="off"
                  label={""}
                  InputProps={{
                    notched: false,
                    sx: {
                      "& legend": { display: "none" },
                    },
                  }}
                  placeholder="Product Name (required)"
                />
              </Box>
              {fieldsWithError.name && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.error[500],
                    paddingLeft: 1,
                    marginTop: 1,
                  }}
                >
                  {fieldsWithError.name}
                </Typography>
              )}
            </Box>
            {/* PRODUCT CLASSIFICATION SECTION */}
            <Box sx={{ marginTop: 2, width: "100%" }}>
              <Typography variant="body2" sx={{ marginTop: 4 }}>
                Product Classification*
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                This could be something like Spirit / Whiskey / Bourbon. At
                minimum Product Family is required
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <Grid container sx={{ width: "100%" }} spacing={2}>
                  <Grid item xs={12}>
                    <AutoCompleteStyled
                      options={productFamilies || []}
                      getOptionLabel={(option) => option.name}
                      error={!!fieldsWithError.productFamily}
                      isOptionEqualToFunc={(o, v) => {
                        return o.id === v.id;
                      }}
                      value={
                        familyMap[productCreationData.productFamilyId] || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleUpdateFamily}
                      handleClear={handleClearFamily}
                      label="Product Family (required)"
                    />
                    {fieldsWithError.productFamily && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.error[500],
                          paddingLeft: 1,
                          marginTop: 1,
                        }}
                      >
                        {fieldsWithError.productFamily}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <AutoCompleteStyled
                      disabled={!productCreationData.productFamilyId}
                      options={
                        productCreationData.productFamilyId
                          ? familyMap[productCreationData.productFamilyId]
                              ?.categories
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToFunc={(o, v) => {
                        return o.id === v.id;
                      }}
                      value={
                        categoryMap[productCreationData.categoryId] || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleUpdateCategory}
                      handleClear={handleClearCategory}
                      label="Category"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AutoCompleteStyled
                      disabled={!productCreationData.categoryId}
                      options={
                        productCreationData.categoryId
                          ? categoryMap[productCreationData.categoryId]
                              ?.subcategories
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToFunc={(o, v) => {
                        return o.id === v.id;
                      }}
                      value={
                        subcategoryMap[productCreationData.subcategoryId] || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleUpdateSubcategory}
                      handleClear={handleClearSubcategory}
                      label="Subcategory"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                height: "2px",
                width: "100%",
                backgroundColor: (theme) => theme.palette.terrain[200],
                marginTop: 8,
              }}
            />

            {/* PRODUCT FORMAT SECTION */}
            <Box sx={{ marginTop: 2, width: "100%" }}>
              <Typography variant="h3" sx={{ marginTop: 6 }}>
                Product Format
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 4 }}>
                Unit Size
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                What is the size of this Product?
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <Grid container sx={{ width: "100%" }} spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      value={productFormatCreationData?.size?.type}
                      label=""
                      onChange={handleUpdateSizeType}
                      displayEmpty
                      size="small"
                      input={
                        <OutlinedInput
                          label="" // No label text
                          notched={false} // Disable the notch
                          sx={{
                            // Hide the legend that creates the notch
                            "& legend": { display: "none" },
                          }}
                        />
                      }
                      sx={{ width: "100%" }}
                    >
                      {sizeTypeSelectOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {" "}
                          {option.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {productFormatCreationData?.size?.unitOfMeasurement
                      ?.isKegSize ? null : (
                  <Grid
                    item
                    xs={
                      productFormatCreationData?.size?.type ===
                      productSizeTypes.Arbitrary
                        ? 12
                        : 6
                    }
                  >
                      <TextField
                        size="small"
                        value={productFormatCreationData?.size?.size}
                        onChange={handleUpdateQuantity}
                        required
                        fullWidth
                        displayEmpty
                        autoComplete="off"
                        error={!!fieldsWithError.size}
                        label={""}
                        placeholder="Amount"
                        sx={{ marginTop: 1 }}
                        InputProps={{
                          disableUnderline: true,
                          inputMode: "numeric",
                          notched: false,
                          sx: {
                            "& legend": { display: "none" },
                          },
                        }}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    {fieldsWithError.size && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.error[500],
                          marginTop: 1,
                          paddingLeft: 1,
                        }}
                      >
                        {fieldsWithError.size}
                      </Typography>
                    )}
                  </Grid>
                  )}
                  {productFormatCreationData?.size?.type !==
                  productSizeTypes.Arbitrary ? (
                    <Grid item xs={productFormatCreationData?.size?.unitOfMeasurement
                      ?.isKegSize ? 12 : 6}>
                        <UnitOfMeasurementSelector currentSizeType={productFormatCreationData?.size?.type} handleUoMUpdate={handleUoMUpdate}
                          productFormatCreationData={productFormatCreationData} unitsOfMeasurement={unitsOfMeasurement}
                          unitSizeToUomSizeTypeMap={unitSizeToUomSizeTypeMap}
                        />
                      {fieldsWithError.unitOfMeasurement && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: (theme) => theme.palette.error[500],
                            marginTop: 1,
                            paddingLeft: 1,
                          }}
                        >
                          {fieldsWithError.unitOfMeasurement}
                        </Typography>
                      )}
                    </Grid>
                  ) : null}
                </Grid>
                <Typography variant="body2" sx={{ marginTop: 4 }}>
                  Packaging Type
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  How is the product packaged (e.g. Bottle)
                </Typography>
                <Box sx={{ marginTop: 2 }}>
                  <Select
                    value={packagingTypeSelection}
                    label=""
                    displayEmpty
                    placeholder="count"
                    size="small"
                    onChange={handleUpdatePackagingType}
                    input={
                      <OutlinedInput
                        label="" // No label text
                        notched={false} // Disable the notch
                        sx={{
                          // Hide the legend that creates the notch
                          "& legend": { display: "none" },
                        }}
                      />
                    }
                    sx={{ width: "100%" }}
                  >
                    {optionsForPackagingType.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {" "}
                        {option.name}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                  {packagingTypeSelection === -1 && (
                    <Box sx={{ marginTop: 3 }}>
                      <TextField
                        size="small"
                        value={customPackagingTypeValue}
                        onChange={handleUpdateCustomPackagingType}
                        required
                        fullWidth
                        autoComplete="off"
                        label={""}
                        error={!!fieldsWithError.customPackagingType}
                        InputProps={{
                          notched: false,
                          sx: {
                            "& legend": { display: "none" },
                          },
                        }}
                        placeholder="Custom Packaging Type (required)"
                      />
                      {packagingTypeSelection === -1 &&
                        fieldsWithError.customPackagingType && (
                          <Typography
                            variant="body2"
                            sx={{ color: (theme) => theme.palette.error[500] }}
                          >
                            {fieldsWithError.customPackagingType}
                          </Typography>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "2px",
                width: "100%",
                backgroundColor: (theme) => theme.palette.terrain[200],
                marginTop: 8,
              }}
            />

            {/* PURCHASE UNIT SECTION */}
            <Box sx={{ marginTop: 2, width: "100%" }}>
              <Typography variant="h3" sx={{ marginTop: 6 }}>
                Purchase Unit
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 4 }}>
                Purchase Unit Name
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                The name used when purchasing (e.g. Case)
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <Select
                  value={purchaseUnitNameSelection}
                  label=""
                  displayEmpty
                  placeholder="count"
                  size="small"
                  onChange={handleUpdatePurchaseUnitNameSelection}
                  input={
                    <OutlinedInput
                      label="" // No label text
                      notched={false} // Disable the notch
                      sx={{
                        // Hide the legend that creates the notch
                        "& legend": { display: "none" },
                      }}
                    />
                  }
                  sx={{ width: "100%" }}
                >
                  {optionsForPurchaseUnitName.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {" "}
                      {option.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
                {purchaseUnitNameSelection === -1 && (
                  <Box sx={{ marginTop: 3 }}>
                    <TextField
                      size="small"
                      value={customPurchaseUnitNameValue}
                      onChange={handleUpdateCustomPurchaseUnitName}
                      required
                      fullWidth
                      autoComplete="off"
                      error={!!fieldsWithError.customPurchaseUnitName}
                      label={""}
                      InputProps={{
                        notched: false,
                        sx: {
                          "& legend": { display: "none" },
                        },
                      }}
                      placeholder="Custom Purchase Unit (required)"
                    />
                  </Box>
                )}
                {purchaseUnitNameSelection == -1 &&
                  fieldsWithError.customPurchaseUnitName && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.error[500],
                        marginTop: 1,
                        paddingLeft: 1,
                      }}
                    >
                      {fieldsWithError.customPurchaseUnitName}
                    </Typography>
                  )}
              </Box>
              <Typography variant="body2" sx={{ marginTop: 4 }}>
                Unit Amount
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                The number of units included in this purchse unit
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                {
                  optionsForPurchaseUnitName.find(
                    (x) => x.id === purchaseUnitNameSelection
                  )?.suggestionText
                }
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  size="small"
                  value={purchaseUnitCreationData.unitAmount}
                  onChange={handleUpdatePurchaseUnitUnitAmount}
                  required
                  fullWidth
                  displayEmpty
                  autoComplete="off"
                  label={""}
                  error={!!fieldsWithError.unitAmount}
                  placeholder="Quantity (required)"
                  sx={{ marginTop: 1 }}
                  InputProps={{
                    disableUnderline: true,
                    inputMode: "numeric",
                    notched: false,
                    sx: {
                      "& legend": { display: "none" },
                    },
                  }}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
              </Box>
              {fieldsWithError.unitAmount && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.error[500],
                    marginTop: 1,
                    paddingLeft: 1,
                  }}
                >
                  {fieldsWithError.unitAmount}
                </Typography>
              )}
              <Typography variant="body2" sx={{ marginTop: 4 }}>
                Price
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                Purchase price per unit amount
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  size="small"
                  value={purchaseUnitCreationData.price?.amount}
                  onChange={handleUpdatePurchaseUnitPrice}
                  required
                  fullWidth
                  displayEmpty
                  autoComplete="off"
                  label={""}
                  error={!!fieldsWithError.price}
                  placeholder="0.00 (required)"
                  sx={{ marginTop: 1 }}
                  InputProps={{
                    disableUnderline: true,
                    inputMode: "numeric",
                    notched: false,
                    sx: {
                      "& legend": { display: "none" },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
              </Box>
              {fieldsWithError.price && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.error[500],
                    marginTop: 1,
                    paddingLeft: 1,
                  }}
                >
                  {fieldsWithError.price}
                </Typography>
              )}
            </Box>
            {/* ADDITIONAL FORMAT DETAILS */}
            <Box
              sx={{
                marginTop: 4,
                width: "100%",
                backgroundColor: (theme) => theme.palette.terrain[200],
                paddingLeft: 2,
                paddingRight: 2,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{ height: "40px", width: "100%", margin: 0, padding: 0 }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "40px",
                  }}
                >
                  <Typography variant="body2">
                    Additional Purchase Unit Details
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "36px",
                    }}
                  >
                    {!purchaseExpanded ? (
                      <IconButton
                        sx={{
                          height: "36px",
                          background: "none",
                          "&:hover": {
                            background: "none",
                            color: (theme) => theme.palette.terrain[400],
                          },
                        }}
                        onClick={handleExpandPurchaseClick}
                      >
                        <KeyboardArrowDownOutlinedIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        sx={{
                          height: "36px",
                          background: "none",
                          "&:hover": {
                            background: "none",
                            color: (theme) => theme.palette.terrain[400],
                          },
                        }}
                        onClick={handleExpandPurchaseClick}
                      >
                        <ExpandLessOutlinedIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Box>
              <Collapse in={purchaseExpanded} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.terrain[200],
                    marginBottom: 2,
                  }}
                >
                  <Typography variant="body2" sx={{ marginTop: 2 }}>
                    Vendor
                  </Typography>
                  {vendorData?.vendors?.length == 0 ? (
                    <Typography variant="body2">
                      You currently have no vendors created. You can add them in
                      the vendors section for use.
                    </Typography>
                  ) : null}
                  <Box sx={{ marginTop: 2 }}>
                    <AutoCompleteStyled
                      disabled={vendorData?.vendors?.length == 0}
                      options={
                        vendorData?.vendors?.length > 0
                          ? vendorData.vendors
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToFunc={(o, v) => o.id === v.id}
                      value={
                        purchaseUnitCreationData?.vendor || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleSelectVendor}
                      handleClear={handleClearVendor}
                      label="Select Vendor"
                    />
                  </Box>
                  <Typography variant="body2" sx={{ marginTop: 4 }}>
                    SKU
                  </Typography>
                  <Box sx={{ marginTop: 2 }}>
                    <TextField
                      size="small"
                      value={purchaseUnitCreationData.SKU}
                      onChange={handleSKUUpdate}
                      required
                      fullWidth
                      autoComplete="off"
                      label={""}
                      InputProps={{
                        notched: false,
                        sx: {
                          "& legend": { display: "none" },
                        },
                      }}
                      placeholder="SKU"
                    />
                  </Box>
                  <Typography variant="body2" sx={{ marginTop: 4 }}>
                    Expense Group
                  </Typography>
                  <Box sx={{ marginTop: 2 }}>
                    <AutoCompleteStyled
                      options={expenseGroupData?.expenseGroups || []}
                      getOptionLabel={(option) => option.name}
                      value={
                        purchaseUnitCreationData?.expenseGroup || {
                          id: -1,
                          name: "",
                        }
                      }
                      isOptionEqualToFunc={(o, v) => o.id === v.id}
                      handleSelect={handleUpdateExpenseGroup}
                      handleClear={handleClearExpenseGroup}
                      label="Select Expense Group"
                    />
                  </Box>
                  <Typography variant="body2" sx={{ marginTop: 4 }}>
                    Expense Subgroup
                  </Typography>
                  <Box sx={{ marginTop: 2 }}>
                    <AutoCompleteStyled
                      disabled={
                        !purchaseUnitCreationData?.expenseGroup ? true : false
                      }
                      options={
                        purchaseUnitCreationData?.expenseGroup
                          ?.expenseSubgroups || []
                      }
                      getOptionLabel={(option) => option.name}
                      value={
                        purchaseUnitCreationData?.expenseSubgroup || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleUpdateExpenseSubgroup}
                      handleClear={handleClearExpenseSubgroup}
                      label="Select Expense Subgroup"
                    />
                  </Box>
                </Box>
              </Collapse>
            </Box>

            {fieldsWithError && Object.keys(fieldsWithError).length > 0 && (
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  marginTop: 4,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.error[500] }}
                >
                  One ore more Required fields are missing
                </Typography>
              </Box>
            )}
            {/* BUTTON */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
              }}
            >
              <Button
                variant="contained"
                disabled={loading}
                sx={{
                  width: "300px",
                  height: "40px",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                }}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress size={24} color="grey" />
                ) : (
                  <Typography variant="largeButton">Create Product</Typography>
                )}
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default CreateProductModal;
