import React, { useState } from "react";
import { modalBoxStyle } from "../styles";
// nodejs library to set properties for components
import {
  Grid,
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Divider,
  MenuItem,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import ImageControl from "../../ImageControl";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment-timezone";

const CenteredContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const FormInputLabelStyles = {
  shrink: true,
};

const TextFieldStyles = {
  marginBottom: "32px",
};

const commonTimezones = [
  "Europe/London", // GMT/BST
  "Europe/Paris", // CET/CEST
  "America/New_York", // EST/EDT
  "America/Chicago", // CST/CDT
  "America/Denver", // MST/MDT
  "America/Los_Angeles", // PST/PDT
  "America/Anchorage",
  "Pacific/Honolulu",
  "Asia/Tokyo", // JST
  "Asia/Shanghai", // CST
  "Asia/Singapore", // SGT
  "Asia/Kolkata", // IST
  "Australia/Sydney", // AEST/AEDT,
  "Australia/Melbourne",
  "Australia/Brisbane",
  "Australia/Adelaide",
  "Australia/Perth",
  "Australia/Darwin",
  "Australia/Hobart",
];

const NewEstablishmentModal = ({
  handleCreateEstablishment,
  handleModalToggle,
  showCreateNew,
  creatingFirstEst,
  loading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "all" });
  const [imageDestination, setImageDestination] = useState(null);
  const [isImageControlOpen, toggleIsImageControlOpen] = useState(false);

  const disabledButtonBackgroundColor = !isValid ? "#c6c6c6" : "";

  const imageControlCallback = async (photoLocation) => {
    setImageDestination(photoLocation);
    toggleIsImageControlOpen(false);
  };

  const handleSubmitForm = async (data) => {
    handleCreateEstablishment({
      imageURL: imageDestination,
      name: data.establishmentName,
      display: true,
      active: true,
      Address: {
        addressLine1: data.address,
        city: data.city,
        stateProvince: data.state,
        postalCode: data.zip,
        addressLine2: data.website,
      },
      inventorySettings: {
        timezone: data.timezone,
        currency: data?.timezone === "Europe/London" ? 2 : 0,
        locale: navigator.languages[0],
      },
    });
  };

  return (
    <Modal
      open={showCreateNew || loading}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography variant="h4" sx={{ marginBottom: ".5rem" }}>
              {" "}
              Create New Establishment{" "}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              width: "40px",
              padding: "0px",
              margin: "0px",
              marginBottom: "1rem",
            }}
          >
            <Button
              onClick={handleModalToggle}
              sx={{ marginBottom: "1rem", margin: "0px", padding: "0px" }}
            >
              <CloseIcon
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  padding: "0px",
                  margin: "0px",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ borderBottom: 2, marginBottom: 4 }} />
        {creatingFirstEst && null}
        <ImageControl
          imageSrc={imageDestination}
          imageControlCallback={imageControlCallback}
          isImageControlOpen={isImageControlOpen}
          toggleIsImageControlOpen={toggleIsImageControlOpen}
        />
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: 8 }}>
            <TextField
              size="small"
              required
              label="Establishment Name"
              disabled={loading}
              id="name"
              placeholder="Enter Establishment Name"
              name="establishmentName"
              InputLabelProps={FormInputLabelStyles}
              fullWidth
              sx={TextFieldStyles}
              {...register("establishmentName", {
                required: true,
              })}
            />
            <TextField
              size="small"
              required
              select
              label="Timezone"
              id="timezone"
              name="timezone"
              disabled={loading}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={TextFieldStyles}
              defaultValue={
                commonTimezones.find((x) => x === moment.tz.guess()) ||
                "America/Los_Angeles"
              }
              {...register("timezone", { required: true })}
            >
              {commonTimezones.map((timezone) => (
                <MenuItem key={timezone} value={timezone}>
                  {timezone}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              size="small"
              required
              label="Website"
              id="website"
              disabled={loading}
              placeholder="Enter Website Address"
              name="website"
              fullWidth
              InputLabelProps={FormInputLabelStyles}
              sx={TextFieldStyles}
              {...register("website", { required: true })}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                size="small"
                required
                label="Address"
                id="address"
                disabled={loading}
                placeholder="Enter Address"
                name="address"
                InputLabelProps={FormInputLabelStyles}
                sx={{
                  marginBottom: "32px",
                  width: "250px",
                  marginRight: { xs: 1, md: 0 },
                }}
                {...register("address", { required: true })}
              />
              <TextField
                size="small"
                required
                label="State"
                id="state"
                disabled={loading}
                placeholder="Enter State"
                name="state"
                sx={{ width: "250px" }}
                InputLabelProps={FormInputLabelStyles}
                {...register("state", { required: true })}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                size="small"
                required
                label="City"
                disabled={loading}
                id="city"
                placeholder="Enter City"
                name="city"
                sx={{ width: "250px", marginRight: { xs: 1, md: 0 } }}
                InputLabelProps={FormInputLabelStyles}
                {...register("city", { required: true })}
              />
              <TextField
                size="small"
                required
                label="Zip Code"
                disabled={loading}
                id="zip"
                placeholder="Enter Zip Code"
                name="zip"
                sx={{ width: "250px" }}
                InputLabelProps={FormInputLabelStyles}
                {...register("zip", { required: true })}
              />
            </Box>
          </Box>

          <Box sx={CenteredContainerStyles}>
            <Box sx={{ display: "flex", marginTop: "1rem" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || loading}
                sx={{
                  background: `${disabledButtonBackgroundColor}`,
                  border: "none",
                  borderRadius: 2,
                  marginTop: "20px",
                  padding: "8px 60px",
                }}
              >
                <Typography variant="smallButton">
                  {loading ? <CircularProgress color="inherit" /> : "Submit"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

NewEstablishmentModal.propTypes = {};

export default NewEstablishmentModal;
