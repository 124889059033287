import { MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { productSizeTypes } from "../../../../clients/inventoryObjects";

const UnitOfMeasurementSelector = ({
  productFormatCreationData,
  currentSizeType,
  handleUoMUpdate,
  unitsOfMeasurement,
  unitSizeToUomSizeTypeMap,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (currentSizeType === productSizeTypes.Volumetric && unitsOfMeasurement) {
      setOptions([
        ...unitsOfMeasurement.filter(
          (x) =>
            x.type === unitSizeToUomSizeTypeMap[currentSizeType] && !x.isKegSize
        ),
        ...unitsOfMeasurement.filter(
          (x) =>
            x.type === unitSizeToUomSizeTypeMap[currentSizeType] && x.isKegSize
        ),
      ]);
    } else if (
      currentSizeType === productSizeTypes.Mass &&
      unitsOfMeasurement
    ) {
      setOptions([
        ...unitsOfMeasurement.filter(
          (x) => x.type === unitSizeToUomSizeTypeMap[currentSizeType]
        ),
      ]);
    } else {
      setOptions([]);
    }
  }, [currentSizeType, unitsOfMeasurement]);

  return (
    <Select
      value={productFormatCreationData?.size?.unitOfMeasurement?.id}
      disabled={
        !currentSizeType || currentSizeType === productSizeTypes.Arbitrary
      }
      label=""
      displayEmpty
      onChange={handleUoMUpdate}
      renderValue={(v) => {
        if (v) {
          const uomValue = unitsOfMeasurement.find((x) => x.id === v);
          if (uomValue?.isKegSize) {
            return uomValue?.kegName;
          }

          return unitsOfMeasurement.find((x) => x.id === v)?.name || "Count";
        }
        return "Count";
      }}
      placeholder="count"
      size="small"
      input={
        <OutlinedInput
          label="" // No label text
          notched={false} // Disable the notch
          sx={{
            // Hide the legend that creates the notch
            "& legend": { display: "none" },
          }}
        />
      }
      sx={{ width: "100%", marginTop: 1 }}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {" "}
          {option.isKegSize ? option.kegName : option.name}{" "}
        </MenuItem>
      ))}
    </Select>
  );
};

export default UnitOfMeasurementSelector;
